<template>
<div class="stammdaten-display" v-if="stammdaten">
  <div class="row">
    <div class="col s12">
      <h5>Stammdaten</h5>
    </div>
    <div class="input-field col s12 m3">
      <input id="id" type="text" :value="stammdaten.id" disabled>
      <label for="id">Sponsor-ID</label>
    </div>
    <div class="input-field col s12 m9">
      <input id="kontoname" type="text" :value="stammdaten.sponsorName" disabled>
      <label for="kontoname">Sponsorname</label>
    </div>
    <div class="input-field col s12">
      <input id="name" type="text" disabled :value="`${stammdaten.anrede} ${stammdaten.akgrad ? stammdaten.akgrad : ''} ${stammdaten.vorname} ${stammdaten.name}`">
      <label for="name">Name</label>
    </div>
    <div class="input-field col s12">
      <input id="adresse" type="text" disabled :value="`${stammdaten.strasse},
       ${stammdaten.plz} ${stammdaten.ort}`">
      <label for="adresse">Anschrift</label>
    </div>
    <div class="input-field col s12 m6">
      <input id="telefon" type="text" disabled :value="stammdaten.telefon">
      <label for="telefon">Telefon</label>
    </div>
    <div class="input-field col s12 m6">
      <input id="mobil" type="text" disabled :value="stammdaten.mobil">
      <label for="mobil">Mobil</label>
    </div>
    <div class="input-field col s12">
      <input id="mail" type="text" disabled :value="stammdaten.mail">
      <label for="mail">E-Mail Adresse</label>
    </div>
    <div class="input-field col s12">
      <input id="logoVorhanden" type="text" disabled :value="stammdaten.logoVorhanden">
      <label for="logoVorhanden">Logo liegt vor</label>
    </div>
  </div>
</div>
</template>

<script>
import M from 'materialize-css';

export default {
  name: 'StammdatenDisplay',
  mounted() {
    M.updateTextFields();
  },
  computed: {
    stammdaten() {
      return this.$store.state.stammdaten;
    },
  },
};
</script>

<style scoped>

</style>
