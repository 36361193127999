<template>
<div class="spenden-display">
  <div class="row">
    <div class="col s12">
      <h5>Sponsoring</h5>
    </div>
    <div class="col s12" v-if="gutscheine.length > 0">
      <h6>Warengutscheine</h6>
      <div class="row valign-wrapper" v-for="(gutschein, index) in gutscheine" :key="index">
        <div class="input-field col s2">
          <input :id="`gutscheine-menge-${index}`" disabled :value="gutschein.menge">
          <label :for="`gutscheine-menge-${index}`" class="active">Anzahl</label>
        </div>
        <div class="col s1">
          <p class="center-align">x</p>
        </div>
        <div class="input-field col s9">
          <input :id="`gutscheine-wert-${index}`" disabled :value="`${gutschein.wert} €`">
          <label :for="`gutscheine-wert-${index}`" class="active">Gutscheinwert</label>
        </div>
         <div class="input-field col s9">
          <input :id="`gutscheine-abholort-${index}`" disabled :value="`${gutschein.abholort}`">
          <label :for="`gutscheine-abholort-${index}`" class="active">Abholort</label>
        </div>
        <div class="input-field col s9">
          <input :id="`gutscheine-abholzeit-${index}`" disabled :value="`${gutschein.abholzeit}`">
          <label :for="`gutscheine-abholzeit-${index}`" class="active">Abholzeit</label>
        </div>
      </div>
    </div>
    <div class="col s12" v-if="sachspenden.length > 0">
      <h6>Sachspenden</h6>
    </div>
    <div class="col s12" v-if="sachspenden.length > 0">
      <div class="row" v-for="(spende, index) in sachspenden" :key="index">
        <div class="input-field col s12 m6">
          <input :id="`name-sach-${index}`" type="text" disabled :value="spende.bezeichnung">
          <label :for="`name-sach-${index}`">Bezeichnung</label>
        </div>
        <div class="input-field col s6 m3">
          <input :id="`menge-sach-${index}`" type="number" disabled :value="spende.menge">
          <label :for="`menge-sach-${index}`">Anzahl</label>
        </div>
        <div class="input-field col s6 m3">
          <input :id="`wert-sach-${index}`" type="text" disabled :value="`${spende.wert} €`">
          <label :for="`wert-sach-${index}`">Sachpreiswert</label>
        </div>
        <div class="input-field col s6 m3">
          <input :id="`wert-sach-${index}`" type="text" disabled :value="`${spende.abholort}`">
          <label :for="`wert-sach-${index}`">Abholort</label>
        </div>
        <div class="input-field col s6 m3">
          <input :id="`wert-sach-${index}`" type="text" disabled :value="`${spende.abholzeit}`">
          <label :for="`wert-sach-${index}`">Abholzeit</label>
        </div>
      </div>
    </div>
    <div class="col s12" v-if="barspende > 0">
      <h6>Barspende</h6>
    </div>
    <div class="col s12" v-if="barspende > 0">
      <div class="row">
        <div class="input-field col s12">
          <input id="barspende-wert" type="text" disabled :value="`${barspende} €`">
          <label for="barspende-wert">Wert</label>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'SpendenDisplay',
  computed: {
    gutscheine() {
      return this.$store.state.gutscheine;
    },
    sachspenden() {
      return this.$store.state.sachspenden;
    },
    barspende() {
      return this.$store.state.barspende;
    },
  },
};
</script>

<style scoped>

</style>
