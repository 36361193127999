<template>
<div class="end card">
  <div class="card-content">
    <div class="row">
      <div class="col s12">
        <span class="card-title">Zusammenfassung</span>
      </div>
      <div class="col s12">
        <p>Vielen Dank für Ihre Teilnahme am diesjährigen Lions-Adventskalender. In Kürze erhalten
          Sie eine E-Mail mit der Bestätigung Ihres Sponsorings, sowie eine Kopie Ihrer Antworten.
          Hier finden Sie bereits jetzt eine Übersicht Ihrer gemachten Angaben:</p>
      </div>
    </div>
    <StammdatenDisplay></StammdatenDisplay>
    <SpendenDisplay></SpendenDisplay>
    <div class="row">
      <div class="col s12">
        <h5>Kalender</h5>
      </div>
      <div class="input-field col s12">
        <input id="kalenderCount" disabled type="text" :value="orderedKalender">
        <label for="kalenderCount">Bestellte Kalender</label>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import StammdatenDisplay from '@/components/StammdatenDisplay.vue';
import SpendenDisplay from '@/components/SpendenDisplay.vue';

export default {
  name: 'End',
  components: {
    StammdatenDisplay,
    SpendenDisplay,
  },
  computed: {
    orderedKalender() {
      return `${this.$store.state.bestellteKalender} Stück`;
    },
  },
};
</script>
